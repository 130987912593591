<template>
  <div class="card card-custom position-relative">
    <LoaderPartial v-if="showLoader"></LoaderPartial>
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div class="wizard-steps p-8 p-lg-10">
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label edit row">
                <i class="wizard-icon flaticon-book col"></i>
                <h3 class="wizard-title col">Información General</h3>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label edit row">
                <i class="wizard-icon flaticon-file col"></i>
                <h3 class="wizard-title col">Integración de Servicio</h3>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label edit row">
                <i class="wizard-icon flaticon-notes col"></i>
                <h3 class="wizard-title col">Contenidos y Documentación</h3>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label edit row">
                <i class="wizard-icon flaticon-globe col"></i>
                <h3 class="wizard-title col">Limitaciones de Acceso</h3>
              </div>
            </div>
            <!-- <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label edit row">
                <i class="wizard-icon flaticon-presentation col"></i>
                <h3 class="wizard-title col">Estadisticas de uso</h3>
              </div>
            </div> -->
          </div>
        </div>
        <!--end: Wizard Nav-->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-11">
            <!--begin: Wizard Form-->
            <div class="form" id="kt_form">
              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h3 class="mb-10 font-weight-bold text-dark">
                  Información general
                </h3>
                <div class="form-group">
                  <label>Imagen del servicio</label>
                  <div>
                    <div
                      class="image-input image-input-outline"
                      id="kt_profile_avatar"
                    >
                      <div
                        class="image-input-wrapper"
                        :style="{
                          backgroundImage: `url('${photo}')`,
                          backgroundPosition: 'center center'
                        }"
                      ></div>
                      <label
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Change avatar"
                      >
                        <i class="fa fa-pen icon-sm text-muted"></i>
                        <input
                          type="file"
                          name="profile_avatar"
                          accept=".png, .jpg, .jpeg"
                          @change="onFileChange($event)"
                        />
                        <input type="hidden" name="profile_avatar_remove" />
                      </label>
                      <!-- <span
                        class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title="Remove avatar"
                        @click="current_photo = null"
                      >
                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                      </span> -->
                    </div>
                    <span class="form-text text-muted"
                      >Tipos permitidos: png, jpg, jpeg.</span
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label>Estado del servicio</label>
                  <b-form-checkbox
                    size="lg"
                    class="mt-3"
                    v-model="service.active"
                    >{{
                      service.active ? "Activo" : "Inactivo"
                    }}</b-form-checkbox
                  >
                </div>
                <h3 class="mb-10 font-weight-bold text-dark">
                  Demo
                </h3>
                <div class="form-group">
                  <label>Tipo de modelo</label>
                  <b-form-select
                    v-model="service.type"
                    :options="serviceTypes"
                    class="mb-3 form-control form-control-solid form-control-lg"
                    value-field="item"
                    text-field="name"
                    input-id="type-basic"
                    disabled-field="notEnabled"
                  ></b-form-select>
                  <span class="form-text text-muted"
                    >Define el tipo de pretty a mostrarse como respuesta del demo del servicio</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.service.type.required"
                  >
                    Este campo es obligatorio
                  </div>
                </div>
                <div class="form-group">
                  <label>Tipo de input</label>
                  <b-form-select
                    v-model="service.inputType"
                    :options="serviceInputTypes"
                    class="mb-3 form-control form-control-solid form-control-lg"
                    value-field="item"
                    text-field="name"
                    input-id="input-type"
                    disabled-field="notEnabled"
                  ></b-form-select>
                  <span class="form-text text-muted"
                    >Define el tipo de input a utilizar por el usuario en el demo del servicio</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.service.inputType.required"
                  >
                    Este campo es obligatorio
                  </div>
                </div>
                <div class="form-group" v-if="service.type == 'ss_gpt'">
                  <label>Tipo de archivo (SS GPT)</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    name="name"
                    placeholder="Tipo de archivo"
                    v-model="service.fileType"
                  />
                  <span class="form-text text-muted"
                    >Define el tipo de archivo a utilizar por el modelo</span
                  >
                </div>
                <div class="form-group" ref="documentation" id="demoschema">
                  <p>
                    La variable <b>$textToAnalyze</b> se sustituirá por el texto
                    enviado por el usuario desde el demo del servicio
                  </p>
                  <textarea
                    class="form-control form-control-solid form-control-lg"
                    v-model="service.schema"
                  ></textarea>
                  <span class="form-text text-muted" v-if="service.inputType == 'url'"
                    >Ejemplo {"urls":[$textToAnalyze]}</span
                  >
                  <span class="form-text text-muted" v-else-if="service.inputType == 'file'"
                    >Ejemplo {"file":$textToAnalyze}</span
                  >
                  <span class="form-text text-muted" v-else
                    >Ejemplo {"texts":[$textToAnalyze]}</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.service.schema.required"
                  >
                    Este campo es obligatorio
                  </div>
                </div>
                <div class="form-group"  v-if="service.type == 'ocr_gs' && service.inputType == 'file'">
                  <label>Validar respuesta manualmente</label>
                  <b-form-checkbox
                    size="lg"
                    class="mt-3"
                    v-model="service.validateRequest"
                    >{{
                      service.validateRequest ? "Si" : "No"
                    }}</b-form-checkbox
                  >
                  <span class="form-text text-muted"
                    >Todas las peticiones pasarán primero a una lista de peticiones a verificar por un usuario validador.</span
                  >
                </div>
                <h3 class="mb-10 font-weight-bold text-dark">
                  Versionado
                </h3>
                <div class="form-group">
                  <label>Versión</label>
                  <div class="input-group input-group-lg input-group-solid">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="la">v</i>
                      </span>
                    </div>
                    <input
                      ref="version"
                      type="text"
                      name="version"
                      class="form-control form-control-lg form-control-solid"
                      placeholder="Versión del servicio"
                      v-model="service.version"
                    />
                  </div>
                  <span class="form-text text-muted"
                    >La versión actual del modelo</span
                  >
                </div>

                <div class="form-group">
                  <label>Fecha de creación</label>

                  <b-form-datepicker
                    id="datepicker-creation-date"
                    class="form-control form-control-solid form-control-lg"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :value-as-date="true"
                    v-model="service.serviceCreationDate"
                    locale="es"
                  ></b-form-datepicker>
                  <span class="form-text text-muted"
                    >Fecha en que el modelo se creó</span
                  >
                </div>

                <div class="form-group">
                  <label>Último despliegue</label>
                  <b-form-datepicker
                    id="datepicker-last-deployment"
                    class="form-control form-control-solid form-control-lg"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :value-as-date="true"
                    v-model="service.serviceLastDeploy"
                    locale="es"
                  ></b-form-datepicker>
                  <span class="form-text text-muted"
                    >Fecha en que el modelo se desplegó por última vez</span
                  >
                </div>

                <div class="form-group">
                  <label>Último entrenamiento</label>
                  <b-form-datepicker
                    id="datepicker-last-training"
                    class="form-control form-control-solid form-control-lg"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :value-as-date="true"
                    v-model="service.serviceLastTraining"
                    locale="es"
                  ></b-form-datepicker>
                  <span class="form-text text-muted"
                    >Fecha en que el modelo se entrenó por última vez</span
                  >
                </div>
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Integración de Servicio
                </h4>
                <div class="form-group">
                  <label>Slug</label>
                  <div class="input-group input-group-lg input-group-solid">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="la">/</i>
                      </span>
                    </div>
                    <input
                      ref="slug"
                      type="text"
                      name="slug"
                      class="form-control form-control-lg form-control-solid"
                      placeholder="Slug del servicio"
                      v-model="service.slug"
                    />
                  </div>
                  <span class="form-text text-muted"
                    >Lo usarán los usuarios para acceder al servicio (Ejemplo:
                    /slug)</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.service.slug.required"
                  >
                    Este campo es obligatorio
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.service.slug.minLength"
                  >
                    Son requeridos por lo menos 5 caracteres.
                  </div>
                  <div
                    class="error text-primary"
                    v-if="
                      !$v.service.slug.notExists && !$v.service.slug.$pending
                    "
                  >
                    El slug ya existe.
                  </div>
                </div>
                <div class="form-group">
                  <label>Costo</label>
                  <input
                    type="number"
                    min="0"
                    class="form-control form-control-solid form-control-lg"
                    name="credit-cost"
                    placeholder="Costo en creditos"
                    @keydown="filterKey"
                    v-model="service.creditCost"
                  />
                  <span class="form-text text-muted"
                    >Se le descontarán los creditos</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.service.creditCost.required"
                  >
                    Este campo es obligatorio
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.service.creditCost.minValue"
                  >
                    El costo de creditos debe ser mayor a 0.
                  </div>
                </div>
                <div class="form-group">
                  <label>Url de integración</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    name="url"
                    placeholder="Url de integración de servicio"
                    v-model="service.consumerApiBaseUrl"
                  />
                  <span class="form-text text-muted"
                    >Ingrese la url del servicio a integrar</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.service.consumerApiBaseUrl.required"
                  >
                    Este campo es obligatorio
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.service.consumerApiBaseUrl.url"
                  >
                    El valor ingresado no es una url válida
                  </div>
                </div>
                <div class="form-group">
                  <b-form-group
                    label="Metodos de acceso"
                    v-model="consumerApiRestMethods"
                  >
                    <!-- CHANGE TO b-form-radio-group FOR MULTIPLE SELECT -->
                    <b-form-radio-group
                      id="checkbox-group-2"
                      v-model="consumerApiRestMethods"
                      name="rest-methods"
                      :options="['GET', 'POST' /*,'PUT','PATCH','DELETE'*/]"
                    >
                    </b-form-radio-group>
                  </b-form-group>
                  <span class="form-text text-muted"
                    >Ingrese los metodos aceptados por el servicio</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.service.consumerApiRestMethods.required"
                  >
                    Este campo es obligatorio
                  </div>
                
                </div>
                <div class="form-group">
                  <label>Llamada asincrona</label>
                  <b-form-checkbox
                    size="lg"
                    class="mt-3"
                    v-model="service.isAsyncCall"
                    >{{
                      service.isAsyncCall ? "Asincrono" : "Sincrono"
                    }}</b-form-checkbox
                  >
                </div>
                <div class="form-group" v-if="service.isAsyncCall">
                  <label>Url de llamada asincrona (GET)</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    name="url"
                    placeholder="Url de llamada asincrona"
                    v-model="service.asyncApiBaseUrl"
                  />
                  <span class="form-text text-muted"
                    >Ingrese la url de llamada asincrona del servicio a integrar</span
                  >
                </div>
                <!-- Chequeo de salud: Actualmente deshabilitado -->
                <!-- <div class="form-group">
                  <label>Url de chequeo de salud</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    name="health"
                    placeholder="Url de chequeo de salud"
                    v-model="service.healthCheckUrl"
                  />
                  <span class="form-text text-muted"
                    >Ingrese la url en la que se revisará la salud del servicio</span
                  >
                </div> -->
                <HeaderList 
                  :data-object="service.headers"
                  label="Cabeceras Adicionales"
                  help-text="Ingrese cabeceras adicionales que se mandarán al consumir el servicio"
                  ref="headerList"
                />
                <div class="form-group">
                  <p>
                    Idiomas aceptados por el servicio
                  </p>
                  <b-form-tags
                    class="form-control form-control-solid form-control-lg"
                    placeholder="Agrega un idioma y presiona enter"
                    input-id="service-languages"
                    v-model="service.languages"
                    add-button-text="Agregar"
                    invalid-tag-text="El código de idioma debe contener dos caracteres y no puede ser duplicado"
                    duplicate-tag-text="El código de idioma ya existe"
                    :tag-validator="validateServiceLanguages"
                    @input="service.languages[service.languages.length - 1] = service.languages[service.languages.length - 1].toUpperCase();"
                  ></b-form-tags>
                  <span class="form-text text-muted"
                    >Esto se muestra de manera informativa al usuario que desea probar el servicio. Ejemplo: ES</span
                  >
                </div>
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Step 4-->
              <div class="pb-5 row" data-wizard-type="step-content">
                <div class="form-group">
                  <h4>Contenidos y Documentación</h4><br>
                  <h5>Traducciones Existentes</h5>
                  <div class="list-group mt-5">
                    <div v-for="(translation, i) in translations" :key="i" 
                      class="list-group-item d-flex align-items-center"
                    >
                      <div class="mr-auto">
                        <span>
                          {{translation.localeCode}}
                          <!-- <span v-if="translation.localeCode == service.defaultLocale">(Default)</span> -->
                        </span>
                      </div>
                      <div>
                        <button
                          v-if="translation.localeCode == service.defaultLocale"
                          class="mr-2 btn disabled btn-secondary"
                          disabled
                        >Predeterminado</button>
                        <button
                          v-else
                          class="mr-2 btn btn-outline-secondary"
                          @click="() => {
                            service.defaultLocale = translation.localeCode;
                          }"
                        >Predeterminar</button>
                        <button
                          class="btn btn-outline-primary mr-2"
                          @click="() => {
                            newTranslate = {...translation};
                            editIndex = i;
                            if (translation.localeCode == service.defaultLocale) {
                              editingDefaultLocale = true;
                            }
                            showLocaleModal = true;
                          }"
                        >Editar</button>
                        <button
                          class="btn btn-outline-danger"
                          @click="() => {
                            deleteIndex = i;
                            if (translation.localeCode == service.defaultLocale) {
                              editingDefaultLocale = true;
                            }
                            $refs['delete_confirm_modal'].show();
                          }"
                        ><i class="far fa-trash-alt"></i></button>
                      </div>
                    </div>
                    <div
                      class="error text-primary"
                      v-if="!$v.translations.minLength || !$v.translations.required"
                    >
                      Debes agregar al menos una traducción.
                    </div>
                  </div>
                  <div class="form-group row m-0 mt-2">
                    <div class="mx-auto">
                      <button
                        class="btn btn-primary form-control-solid form-control-md"
                        @click="addTranslate($event)"
                      >
                        Agregar Traducción
                      </button>
                    </div>
                  </div>
                  <EditServiceLocale 
                    :show="showLocaleModal" 
                    :translation-data="newTranslate"
                    :usedLocaleCodes="service.locales"
                    v-on:locale-edit-accept="onLocaleModalAccepted"
                    v-on:locale-edit-cancel="onLocaleModalCancelled"
                  />
                  <b-modal ref="delete_confirm_modal" hide-footer :title="'Confirmar Borrado de Traducción ' + (translations[deleteIndex] ? translations[deleteIndex].localeCode : '')">
                    <div class="d-block">
                      ¿Segur@ que deseas borrar la traducción {{ translations[deleteIndex] ? translations[deleteIndex].localeCode : '' }}?
                    </div>
                    <div class="d-flex justify-content-end">
                      <b-button class="mt-3 mr-2" variant="outline-danger" @click="removeTranslation">Borrar</b-button>
                      <b-button class="mt-3" variant="outline-secondary" @click="$refs['delete_confirm_modal'].hide();">Cancelar</b-button>
                    </div>
                  </b-modal>
                </div>
              </div>
              <!--end: Wizard Step 4-->

              <!--begin: Wizard Step 5-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Limitaciones de uso
                </h4>
                <div class="form-group">
                  <label>Elementos maximos por petición</label>
                  <input
                    type="number"
                    min="1"
                    class="form-control form-control-solid form-control-lg"
                    name="locaddress1"
                    @keydown="filterKey"
                    v-model.number="service.maximumRequestElements"
                  />
                  <span class="form-text text-muted"
                    >Define el numero de elementos maximos por petición de
                    usuario</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.service.maximumRequestElements.required"
                  >
                    Este campo es obligatorio
                  </div>
                </div>
                <div class="form-group">
                  <label>Caracteres maximos por elemento</label>
                  <input
                    type="number"
                    min="1"
                    class="form-control form-control-solid form-control-lg"
                    name="locaddress1"
                    @keydown="filterKey"
                    v-model.number="service.maximumElementCharacters"
                  />
                  <span class="form-text text-muted"
                    >Define el maximo de caracteres por cada elemento de la
                    petición</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.service.maximumElementCharacters.required"
                  >
                    Este campo es obligatorio
                  </div>
                </div>
                <div class="form-group">
                  <label>Fecha de inicio <small>(opcional)</small></label>
                  <b-form-datepicker
                    id="datepicker-init-date"
                    class="form-control form-control-solid form-control-lg"
                    :value-as-date="true"
                    v-model="service.avaliableDateFrom"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="es"
                    reset-button
                  ></b-form-datepicker>
                  <span class="form-text text-muted"
                    >Fecha en que el servicio comenzará a estar disponible</span
                  >
                </div>
                <div class="form-group">
                  <label>Fecha de fin <small>(opcional)</small></label>
                  <b-form-datepicker
                    id="datepicker-finish-date"
                    class="form-control form-control-solid form-control-lg"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :value-as-date="true"
                    v-model="service.avaliableDateTo"
                    locale="es"
                    reset-button
                  ></b-form-datepicker>
                  <span class="form-text text-muted"
                    >Fecha en que el servicio dejará de estar disponible</span
                  >
                </div>
                <div class="form-group">
                  <label>Visibilidad</label>
                  <b-form-select
                    v-model="service.visibility"
                    :options="visibilityOptions"
                    class="form-control form-control-solid form-control-lg"
                    value-field="item"
                    text-field="name"
                    input-id="type-basic"
                    disabled-field="notEnabled"
                  ></b-form-select>
                  <span class="form-text text-muted"
                    >Modo de visibilidad del servicio para los clientes</span
                  >
                  <div
                    class="error text-primary"
                    v-if="!$v.service.visibility.required"
                  >
                    Este campo es obligatorio
                  </div>
                </div>
                <div class="form-group" v-if="service.visibility == 'private'">
                  <label>Búsqueda de clientes</label>
                  <div class="row" style="max-width: calc(100% + 13px);">
                    <vue-bootstrap-typeahead
                      id="tenantSearchBox"
                      class="col-9"
                      v-model="tenantSearch"
                      :data="
                        tenants.filter(
                          tenant =>
                            !tenantAccessGroup.some(t => t.id == tenant.id)
                        )
                      "
                      :serializer="item => item.name"
                      @hit="tenantSelected = $event"
                      @keyup.enter="addClient($event)"
                    />
                    <button
                      class="btn btn-primary col-3 form-control-solid form-control-md"
                      @click="addClient($event)"
                    >
                      Agregar
                    </button>
                  </div>
                  <span class="form-text text-muted"
                    >Escribe al menos 2 letras del nombre del cliente</span
                  >

                  <br />
                  <label>Grupo de acceso</label>
                  <b-list-group>
                    <b-list-group-item
                      v-for="(tenant, i) in tenantAccessGroup"
                      :key="i"
                      class="d-flex justify-content-between align-items-center"
                    >
                      {{ tenant.name }}
                      <b-badge
                        variant="primary"
                        class="m-0"
                        style="cursor:pointer;"
                        @click="tenantAccessGroup.splice(i, 1)"
                        >✖︎</b-badge
                      >
                    </b-list-group-item>
                  </b-list-group>
                  <span class="form-text text-muted"
                    >{{ tenantAccessGroup.length }} Cliente{{
                      tenantAccessGroup.length != 1 ? "s" : ""
                    }}
                    con acceso al servicio actual</span
                  >
                </div>
              </div>
              <!--end: Wizard Step 5-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <!-- <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                    v-if="!service.id"
                  >
                    Anterior
                  </button> -->
                </div>
                <div>
                  <button
                    v-on:click="update"
                    ref="kt_update_service"
                    :disabled="$v.service.$invalid || $v.translations.$invalid"
                    class="btn btn-success btn-lg"
                  >
                    Guardar
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </div>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
#tenantSearchBox input {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
}
.ve-fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  margin: 0 !important;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.wizard-label {
  display: flex;
  align-items: center;
}
.wizard-icon {
  font-size: 1.6em !important;
  margin: 0 !important;
  padding: 0 !important;
  max-width: 50px !important;
}
.wizard-title {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 1.2rem !important;
  text-align: left;
}
.wizard-steps {
  padding: 2rem 1rem;
}
</style>
<style v-if="service.id">
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="done"]
  .edit.wizard-label
  .wizard-icon {
  color: #b5b5c3;
}
.wizard.wizard-1
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="done"]
  .edit.wizard-label
  .wizard-title {
  color: #7e8299;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import LoaderPartial from "@/view/content/LoaderPartial.vue";
import EditServiceLocale from "@/view/pages/services/EditServiceLocale.vue";
import { required, minLength, minValue, url } from "vuelidate/lib/validators";
import { cloneDeep } from 'lodash';
import HeaderList from "@/view/content/HeaderList.vue"

const RESOURCE = "ai-products";
const cv = {
  service: {
    async slugNotExists(value) {
      if (!value) return true;
      var resource = await ApiService.query(RESOURCE, {
        filter: {
          limit: 1,
          where: {
            and: [{ slug: value }]
          }
        }
      });
      let id = window.location.hash.split("/")?.pop();
      console.log("id", id);
      return resource.data.length == 0 || resource.data.pop().id == id;
    }
  }
};
function translate_prototype() {
  return {
    localeCode: "",
    name: "",
    description: "",
    tags: [],
    industries: [],
    documentation: "",
    instructions: ""
  }
};

export default {
  name: "EditService",
  data() {
    return {
      resource: "ai-products",
      consumerApiRestMethods: null,
      visibilityOptions: [
        { item: "public", name: "Publico" },
        { item: "private", name: "Privado" },
        { item: "hidden", name: "Oculto" }
      ],
      serviceTypes: [
        { item: "sentiment", name: "Sentimiento" },
        { item: "classifier", name: "Clasificador" },
        { item: "multiclassifier", name: "Multi Clasificador" },
        { item: "risk", name: "Riesgo" },
        { item: "extractor", name: "Extractor" },
        { item: "summary", name: "Resumen" },
        { item: "keyword", name: "Keyword Extractor" },
        { item: "multimodel", name: "Multi-modelo" },
        { item: "ocr_nano", name: "OCR Nanonets" },
        { item: "ocr_gs", name: "OCR GS" },
        { item: "ss_gpt", name: "SS GPT" },
        { item: "alert", name: "Alerta" },
        { item: "other", name: "Otro" }
      ],
      serviceInputTypes: [
        { item: "text", name: "Texto" },
        { item: "file", name: "File" },
        { item: "url", name: "URL" }
      ],
      newTranslate: {...translate_prototype()},
      translations: [],
      deleteIndex: 0,
      editIndex: null,
      editingDefaultLocale: false,
      showLocaleModal: false,
      service: {
        active: true,
        version: "",
        type: "",
        inputType: "",
        fileType: "",
        slug: "",
        creditCost: 0,
        consumerApiBaseUrl: "",
        consumerApiRestMethods: [],
        healthCheckUrl: "",
        maximumRequestElements: 0,
        maximumElementCharacters: 0,
        avaliableDateFrom: null,
        avaliableDateTo: null,
        image: "",
        schema: "",
        visibility: "",
        tenantAccessGroup: [],
        defaultLocale: "",
        locales: [],
        languages: [],
        headers: {},
        validateRequest: false,
        isAsyncCall: false,
        asyncApiBaseUrl: null,
      },
      default_photo: "media/misc/img_placeholder.jpg",
      current_photo: null,
      tenants: [],
      tenantSearch: "",
      tenantSelected: null,
      tenantAccessGroup: [],
      showLoader: true
    };
  },
  components: {
    VueBootstrapTypeahead,
    LoaderPartial,
    EditServiceLocale,
    HeaderList
  },
  validations: {
    service: {
      type: {
        required
      },
      inputType: {
        required
      },
      visibility: {
        required
      },
      slug: {
        required,
        minLength: minLength(5),
        notExists: cv.service.slugNotExists
      },
      creditCost: {
        required,
        minValue: minValue(1)
      },
      consumerApiBaseUrl: {
        required,
        url
      },
      consumerApiRestMethods: {
        required
      },
      maximumRequestElements: {
        required
      },
      maximumElementCharacters: {
        required
      },
      schema: { 
        required 
      },
      headers: {
        valid: function () {
          return this.$refs.headerList.valid;
        }
      }
    },
    translations: {
      required,
      minLength: minLength(1)
    },

    step: {
      1: [
        "service.type",
        "service.inputType",
        "service.schema"
      ],
      2: [
        "service.slug",
        "service.creditCost",
        "service.consumerApiBaseUrl",
        "service.consumerApiRestMethods"
      ],
      3: [
        "service.name",
        "service.description",
        "service.tags",
      ],
      4: [
        "service.maximumRequestElements",
        "service.maximumElementCharacters",
        "service.visibility"
      ]
    }
  },
  editorConfig: {
    extraPlugins: 'font',
    font_names: 'Arial;Arial Black;Courier New;Times New Roman;Poppins/Poppins,sans-serif;Roboto/Roboto,sans-serif;Verdana;',
    toolbar: [
      { name: 'document', items: [ 'Source', '-', 'Save', 'NewPage', 'ExportPdf', 'Preview', 'Print'] },
      { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
      { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
      { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
      { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
      { name: 'insert', items: [ 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak'] },
      '/',
      { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] }
    ]
  },
  async mounted() {
    let getResource;
    try {
      getResource = await this.getResource(this.$route.params.id);
      
    } catch (e) {
      console.log(404);
      this.$router.push({ name: "404" });
    }
    if (!getResource.headers) {
      getResource.headers = {};
    }
    if (!getResource.locales) {
      getResource.locales = [];
    }
    if (getResource.avaliableDateTo) {
      getResource.avaliableDateTo = new Date(getResource.avaliableDateTo);
    }
    if (getResource.avaliableDateFrom) {
      getResource.avaliableDateFrom = new Date(getResource.avaliableDateFrom);
    }
    this.service = {...this.service, ...getResource};
    
    this.translations = await this.getTranslations(this.$route.params.id)
    console.log("trans",this.translations)
    this.consumerApiRestMethods = getResource.consumerApiRestMethods[0];

    this.tenants = await this.getClients();
    console.log(getResource.tenantAccessGroup, this.tenants);
    this.tenantAccessGroup = this.tenants.filter(tenant =>
      getResource.tenantAccessGroup?.some(tenantId => tenant.id == tenantId)
    );

    this.current_photo = this.service.image;
    console.log(this.current_photo);

    let defaultLocale = this.translations.find(t => t.localeCode === getResource.defaultLocale);

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Servicios", route: "/services" },
      { title: defaultLocale ? defaultLocale.name : 'Traducción Pendiente' }
      // { title: getResource.name }
    ]);

    // if (getResource?.documentation)
    //   this.getEditor("documentation").innerHTML = getResource.documentation;

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v1", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function(/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });

    // document.querySelectorAll(".ve-select")[2].style = "display:none;";
    this.showLoader = false;
  },
  methods: {
    filterKey(e) {
      const key = e.key;

      // If is '.' key, stop it
      if (key === ".") return e.preventDefault();

      // OPTIONAL
      // If is 'e' key, stop it
      if (key === "e") return e.preventDefault();
    },

    // This can also prevent copy + paste invalid character
    filterInput(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
    },
    addTranslate(event){
      event.preventDefault();
      this.editIndex = null;
      this.showLocaleModal = true;
    },
    addClient(event) {
      event.preventDefault();
      if (this.tenantSelected) {
        console.log(this.tenantSelected);
        this.tenantAccessGroup.push(this.tenantSelected);
        this.tenantSelected = null;
        this.tenantSearch = null;
        console.log(this.tenantSelected, this.tenantSearch);
      }
    },
    isValidStep(wizard) {
      if (this.$v.step[wizard.getStep()].$invalid) {
        Swal.fire({
          title: "No es posible continuar",
          text: "Por favor revisa que los datos ingresados sean correctos",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
        return false;
      }

      return true;
    },
    async onFileChange(e) {
      const file = e.target.files[0];

      if (!this.service.image) this.service.image = null;

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }

      let formData = new FormData();

      formData.append("image", file);

      let { data } = await ApiService.post(`file-upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      setTimeout(() => {
        this.service.image = data.s3Files[0].completePath;
        console.log(data.s3Files[0].completePath, this.service.image);
      }, 500);
    },
    async getTranslations(id){
      let { data } = await ApiService.get(`${this.resource}`, `${id}/locales`);
      console.log(data)
      return data;
    },
    async getResource(id, retry = 3) {
      try {
        let { data } = await ApiService.get(`${this.resource}`, id);
        // console.log({ ...data });
        return data;
      } catch (error) {
        if (retry > 0) {
					console.log("(Service) getResource Failed... retrying");
					await setTimeout( async () => {
						await this.getResource(id, retry-1);
					}, 200);
					return true;
				}
				console.log("(Service) getResource Failed... no more attempts");
				console.log(error);
				throw(error);
      }
    },
    async update(e) {
      e.preventDefault();
      let { id, ...service } = {
        ...this.service,
        creditCost: parseInt(this.service.creditCost)
      };
      const submitButton = this.$refs["kt_update_service"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;

      service.tenantAccessGroup = this.tenantAccessGroup.map(
        tenant => tenant.id
      );
      if (service.image == null || service.image == "null")
        delete service.image;
      // console.log(service);
      
      let translations = cloneDeep(this.translations);

      service.headers = this.$refs.headerList.getHeaderObject();

      // console.log("origTranslations", this.translations);
      // console.log("translations", translations);

      // console.log("data", {aiProduct: service, aiProductLocales: translations} );

      try {
        let { data } = await ApiService.patch(
          `${this.resource}/${id}`,
          {aiProduct: service, aiProductLocales: translations}
        );
        console.log(data);
        Swal.fire({
          title: "",
          text: "El servicio ha sido guardado exitosamente!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        }).then(function() {
          window.location.href = "#/services";
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        });
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "El servicio no ha sido actualizado",
          text: "Por favor revisa que los datos ingresados sean correctos",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.disabled = false;
      }
    },
    async getClients() {
      let { data } = await ApiService.get(`tenancies`);
      console.log({ ...data });
      return data;
    },
    onLocaleModalAccepted() {
      if (this.editIndex === null) {
        this.newTranslate.product = new String(this.service.id);
        this.translations.push({...this.newTranslate});
      }
      else {
        this.translations[this.editIndex] = {...this.newTranslate};
      }
      if (this.editingDefaultLocale || this.translations.length === 1) {
        this.service.defaultLocale = new String(this.newTranslate.localeCode);
        this.editingDefaultLocale = false;
      }
      this.newTranslate = {...translate_prototype()};
      this.updateServiceLocalesList();
      this.editIndex = null;
      this.showLocaleModal = false;
    },
    onLocaleModalCancelled() {
      // console.log(translate_prototype());
      this.newTranslate = {...translate_prototype()};
      this.editIndex = null;
      this.showLocaleModal=false;
      this.editingDefaultLocale = false;
    },
    validateServiceLanguages(input) {
      let found = this.service.languages.find(e => e == input.toUpperCase());
      if (found !== undefined || input.length > 2) {
        return false;
      }
      return true;
    },
    updateServiceLocalesList() {
      let locales = [];
      this.translations.forEach(element => {
        locales.push(element.localeCode);
      });
      this.service.locales = [...locales];
    },
    removeTranslation() {
      this.translations.splice(this.deleteIndex, 1);
      if (this.editingDefaultLocale) {
        if (this.translations.length > 0) {
          this.service.defaultLocale = new String(this.translations[0].localeCode);
        }
        else {
          this.service.defaultLocale = '';
        }
      }
      this.editingDefaultLocale = false;
      this.updateServiceLocalesList();
      this.$refs['delete_confirm_modal'].hide();
    }
  },
  watch: {
    consumerApiRestMethods: function(restMethod) {
      if (typeof restMethod == "string")
        this.service.consumerApiRestMethods = [restMethod];
      else this.service.consumerApiRestMethods = restMethod;

      console.log(this.service.consumerApiRestMethods);
    },
    "service.slug": {
      handler: function(value) {
        if (value)
          this.service.slug = value
            .replace(/[^a-zA-Z0-9_-]/g, "")
            .replace(" ", "")
            .toLowerCase();
        //this.service.slug = value.replace(/[`´~!¡[@#$%^&*()_|+/=?¿·€ºªÇñáéíäëïöüóú¨;:'",.<>{}\]]/gi, "").replace(" ","").toLowerCase();
      },
      immediate: true
    },

    "newTranslate.localeCode": {
      handler: function(value) {
        if (value)
          this.newTranslate.localeCode = value
            .replace(/[^a-zA-Z0-9_-]/g, "")
            .replace(" ", "")
            .toUpperCase();
      },
      immediate: true
    },
    "service.creditCost": {
      handler: function(credits) {
        // Do something here.
        this.service.creditCost = parseInt(credits);
      },
      immediate: true
    }
  },
  computed: {
    nextButtonValid() {
      if (this.wizard)
        if (typeof this.wizard.getStep == "function") {
          let step = this.wizard.getStep();
          if (!this.$v.step[step]) return true;
          if (!this.$v.step[step]?.$invalid) return true;
        }
      return false;
    },
    photo() {
      console.log("servicePhoto", this.current_photo);
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    }
  }
};
</script>
